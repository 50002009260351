/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AimOutlined, BankOutlined, FilterFilled } from '@ant-design/icons';
import {
    Checkbox,
    Descriptions,
    Divider,
    Image,
    Input,
    List,
    Modal,
    Select,
    Spin,
    Switch,
    Tag,
} from 'antd';
import { Footer, Header } from 'antd/lib/layout/layout';
import { latLngBounds, LatLng, divIcon, Map, LatLngBounds } from 'leaflet';

import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import './App.css';
import { CITIES, INSTITUTE_TYPES, PROVINCES, REGIONS, SERVICES_COLORS } from './constants';
import { Coordinate, CulturalSite, getAllCulturalSites, getCulturalSite } from './services';
import { groupCoordinatesByRadius, isDefined } from './utils';

function renderListItem(item: CulturalSite, onClick: any) {
    return (
        <List.Item
            key={item.id}
            // style={{ marginLeft: -16 }}
            onClick={() => onClick(item)}
        >
            <List.Item.Meta
                title={item.title}
                style={{ marginBottom: 0 }}
                // description={item.type}
                avatar={
                    <div className="search-thumbnail-default">
                        {item.image_url?.match(/.png|.jpg|.gif/) && (
                            <Image
                                alt={item.title}
                                className="search-thumbnail"
                                // width={200}
                                // height={150}
                                src={item.image_url}
                                preview={false}
                            />
                        )}
                    </div>
                }
                description={
                    <>
                        {/* <div>ID: {item.id}</div> */}
                        {/* <div>Tipo: {item.type}</div> */}
                        <div style={{ color: '#383838' }}>
                            <div>
                                {item.fulladdress} {item.zipcode}
                            </div>
                            <div>Regione: {item.region}</div>
                            <div>{item.websites && item.websites[0]}</div>
                            <span style={{ color: 'grey' }}>
                                #{item.id} - {item.relevancy}
                            </span>
                            <div style={{ marginTop: 10 }}>
                                {item.services?.map((service) => (
                                    <Tag key={service} color={SERVICES_COLORS[service]}>
                                        {service}
                                    </Tag>
                                ))}
                            </div>
                        </div>
                    </>
                }
            />
            {/* {extractTextFromHtml(item.description, 100)} */}
        </List.Item>
    );
}
function filterBySearch(
    searchCities: string[],
    setSearchProvinces: React.Dispatch<React.SetStateAction<string[]>>,
    setSearchRegions: React.Dispatch<React.SetStateAction<string[]>>,
    setFilteredCulturalSites: React.Dispatch<React.SetStateAction<CulturalSite[]>>,
    culturalSites: CulturalSite[],
    searchTypes: string[],
    searchRegions: string[],
    searchProvinces: string[],
    searchText?: string,
    mapBounds?: LatLngBounds,
) {
    // if (searchCities.length === 1) {
    //     const province = CITIES[searchCities[0] as keyof typeof CITIES];
    //     const region = PROVINCES[province as keyof typeof PROVINCES];
    //     if (!searchProvinces.includes(province) || !searchRegions.includes(region)) {
    //         setSearchProvinces([province]);
    //         setSearchRegions([region]);
    //         return;
    //     }
    // }

    setFilteredCulturalSites(
        culturalSites
            .filter(
                (cs) =>
                    // type search q
                    (searchTypes.length === 0 ||
                        searchTypes.some((v) => v.includes(cs.type) || cs.type.includes(v))) &&
                    // region search
                    (searchRegions.length === 0 ||
                        searchRegions.some(
                            (v) => v.includes(cs.region) || cs.region.includes(v),
                        )) &&
                    // province search
                    (searchProvinces.length === 0 ||
                        searchProvinces.some(
                            (v) => v.includes(cs.province) || cs.province.includes(v),
                        )) &&
                    // city search
                    (searchCities.length === 0 ||
                        searchCities.some((v) => v.includes(cs.city) || cs.city.includes(v))) &&
                    // name search
                    (searchText === undefined ||
                        cs.title.toLowerCase().includes(searchText.toLowerCase()) ||
                        cs.city.toLowerCase().includes(searchText.toLowerCase())) &&
                    // map search
                    (mapBounds === undefined || mapBounds.contains(cs)),
            )
            .sort((a, b) => {
                const aImage =
                    isDefined(a.image_url) && a.image_url?.match(/.png|.jpg|.gif/) !== null;
                const bImage =
                    isDefined(b.image_url) && b.image_url?.match(/.png|.jpg|.gif/) !== null;
                const aCapo = a.city?.toLowerCase() === a.province?.toLowerCase();
                const bCapo = b.city?.toLowerCase() === b.province?.toLowerCase();

                if (aImage !== bImage) return aImage ? -1 : 1;
                if (aCapo !== bCapo) return aCapo ? -1 : 1;
                // aCapo === bCapo
                return Math.abs(a.relevancy - b.relevancy) < 200
                    ? Math.random() < 0.5
                        ? -1
                        : 1
                    : b.relevancy - a.relevancy;
            }),
    );
}

function serviceTagRender({ value, closable, onClose }: any) {
    return (
        <Tag
            closable={closable}
            onClose={onClose}
            color={SERVICES_COLORS[value as keyof typeof SERVICES_COLORS]}
        >
            {value}
        </Tag>
    );
}

let lastSearch: any;
let searchText: string;
let mapInstance: Map;

const App: React.FC = () => {
    const [culturalSites, setCulturalSites] = useState<CulturalSite[]>([]);
    const [filteredCulturalSites, setFilteredCulturalSites] = useState<CulturalSite[]>([]);
    const [searchRegions, setSearchRegions] = useState<string[]>([]);
    const [searchTypes, setSearchTypes] = useState<string[]>(['Museo']);
    const [searchProvinces, setSearchProvinces] = useState<string[]>([]);
    const [searchCities, setSearchCities] = useState<string[]>([]);
    // const [autoCompleteOptions, setAutoCompleteOptions] = useState<{ value: string }[]>([]);
    const [isLoadingSingle, setLoadingSingle] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [outlinedItem, setOutlinedItem] = useState<CulturalSite | undefined>();
    const [isViewing, setViewing] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [mapBounds, setMapBounds] = useState<LatLngBounds>();
    const [mapZoom, setMapZoom] = useState<number>(5);

    const viewSingleResult = async (item: CulturalSite) => {
        setLoadingSingle(true);
        setViewing(true);
        const fullItem = await getCulturalSite(item.id);
        if (fullItem) {
            setOutlinedItem(fullItem);
            setLoadingSingle(false);
        }
        // console.log(fullItem);
    };

    useEffect(() => {
        setLoading(true);
        clearTimeout(lastSearch);
        lastSearch = setTimeout(() => {
            filterBySearch(
                searchCities,
                setSearchProvinces,
                setSearchRegions,
                setFilteredCulturalSites,
                culturalSites,
                searchTypes,
                searchRegions,
                searchProvinces,
                searchText,
            );
            setLoading(false);
        }, 300);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTypes, searchRegions, searchProvinces, searchCities, culturalSites]);

    useEffect(() => {
        setLoading(true);
        clearTimeout(lastSearch);
        filterBySearch(
            searchCities,
            setSearchProvinces,
            setSearchRegions,
            setFilteredCulturalSites,
            culturalSites,
            searchTypes,
            searchRegions,
            searchProvinces,
            searchText,
            mapBounds,
        );
        lastSearch = setTimeout(() => {
            setLoading(false);
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapBounds]);

    useEffect(() => {
        setFetching(true);
        getAllCulturalSites().then((data) => {
            setCulturalSites(data);
            // setAutoCompleteOptions(data.map((ci) => ({ value: ci.title })));
            // setFilteredCulturalSites(data);
            setFetching(false);
        });
    }, []);

    const onSearch = (txt?: string, ev?: any) => {
        searchText = txt || ev?.target?.value;
        setLoading(true);
        clearTimeout(lastSearch);
        lastSearch = setTimeout(() => {
            filterBySearch(
                searchCities,
                setSearchProvinces,
                setSearchRegions,
                setFilteredCulturalSites,
                culturalSites,
                searchTypes,
                searchRegions,
                searchProvinces,
                searchText,
            );
            setLoading(false);
        }, 1000);
    };

    return (
        <Spin tip="Caricamento in corso..." spinning={isFetching} size="large">
            <div className="App">
                <Header style={{ width: '100%' }}>
                    <h2 className="title">Luoghi della Cultura in Italia</h2>
                </Header>
                <div className="wrapper">
                    <div className="search">
                        {/* <AutoComplete
                        style={{ width: '100%' }}
                        options={autoCompleteOptions}
                        onSelect={onSelect}
                        onSearch={onSearch}
                    > */}
                        <Input.Search
                            size="large"
                            placeholder="Cerca per denominazione..."
                            enterButton
                            allowClear
                            onChange={(ev) => onSearch(undefined, ev)}
                            onSearch={(txt) => onSearch(txt)}
                            className="search-input"
                            style={{ marginBottom: 10 }}
                        />
                        {/* </AutoComplete> */}
                        <div className="search-filters">
                            <div className="filter-control">
                                <AimOutlined className="prefixIcon" />
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    allowClear
                                    value={searchRegions}
                                    onChange={(vals: any) => setSearchRegions(vals)}
                                    placeholder="Regione"
                                >
                                    {REGIONS.map((region) => (
                                        <Select.Option value={region} key={region}>
                                            {region}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <Divider type="vertical" />
                            <div className="filter-control">
                                <AimOutlined className="prefixIcon" />
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    allowClear
                                    value={searchProvinces}
                                    onChange={(vals: any) => setSearchProvinces(vals)}
                                    placeholder="Provincia"
                                >
                                    {Object.keys(PROVINCES).map((province) => (
                                        <Select.Option value={province} key={province}>
                                            {province}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <Divider type="vertical" />
                            <div className="filter-control">
                                <AimOutlined className="prefixIcon" />
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    allowClear
                                    onChange={(vals: any) => setSearchCities(vals)}
                                    placeholder="Comune"
                                >
                                    {Object.keys(CITIES).map((city) => (
                                        <Select.Option value={city} key={city}>
                                            {city}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            <Divider type="vertical" />

                            <div className="filter-control">
                                <BankOutlined className="prefixIcon" />
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    allowClear
                                    value={searchTypes}
                                    placeholder="Tipologia"
                                    onChange={(vals: any) => setSearchTypes(vals)}
                                >
                                    {INSTITUTE_TYPES.map((type) => (
                                        <Select.Option value={type} key={type}>
                                            {type}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>

                            {/* <Divider type="vertical" /> */}
                            {/* <div className="filter-control">
                                <CoffeeOutlined className="prefixIcon" />
                                <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    // style={{ width: '33%' }}
                                    allowClear
                                    onChange={(vals: any) => setSearchServices(vals)}
                                    placeholder="Servizi"
                                    tagRender={serviceTagRender}
                                >
                                    {Object.keys(SERVICES_COLORS).map((service) => (
                                        <Select.Option value={service} key={service}>
                                            {service}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div> */}

                            <Divider type="vertical" />

                            {/* // filteredCulturalSites.length <= 250 && */}
                            <>
                                Mappa
                                <Switch
                                    // disabled={}
                                    style={{ marginLeft: 7 }}
                                    onChange={(ch) => {
                                        setMapBounds(undefined);
                                        setMapZoom(5);
                                        setShowMap(ch);
                                    }}
                                    checked={showMap}
                                />
                            </>
                        </div>
                    </div>
                    <Divider />
                    {/* {searchCities.length === 1 && (
                        <>
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item>{searchRegions[0]}</Breadcrumb.Item>
                                <Breadcrumb.Item>{searchProvinces[0]}</Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    {searchCities[0]}{' '}
                                    {searchTypes.length === 1 && (
                                        <Breadcrumb.Item>| {searchTypes[0]}</Breadcrumb.Item>
                                    )}{' '}
                                    {searchText && <>| {searchText}</>}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </>
                    )}
                    <Divider /> */}
                    <Spin spinning={isLoading}>
                        {showMap && (
                            <MapContainer
                                center={[
                                    Number(filteredCulturalSites[0]?.lat || 45.43707),
                                    Number(filteredCulturalSites[0]?.lng || 12.342447),
                                ]}
                                zoom={5}
                                style={{
                                    zIndex: 0,
                                    height: 450,
                                    boxShadow: '0 0 10px grey',
                                    borderRadius: 7,
                                    marginBottom: 20,
                                    border: '1px solid grey',
                                }}
                                whenCreated={(map) => {
                                    mapInstance = map;
                                    map.on('moveend', () => {
                                        setLoading(true);
                                        setMapBounds(map.getBounds());
                                        setMapZoom(map.getZoom());
                                    });
                                }}
                                // scrollWheelZoom={false}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {filteredCulturalSites.length <= 250
                                    ? filteredCulturalSites.map(
                                          (cs) =>
                                              cs?.lat &&
                                              cs?.lng && (
                                                  <Marker key={cs.id} position={cs}>
                                                      <Popup>
                                                          <div
                                                              className="search-thumbnail-default"
                                                              style={{ maxHeight: 170 }}
                                                          >
                                                              {cs.image_url?.match(
                                                                  /.png|.jpg|.gif/,
                                                              ) && (
                                                                  <Image
                                                                      alt={cs.title}
                                                                      className="search-thumbnail"
                                                                      width={200}
                                                                      // height={150}
                                                                      src={cs.image_url}
                                                                      preview={false}
                                                                  />
                                                              )}
                                                          </div>
                                                          <a
                                                              href="#"
                                                              style={{
                                                                  display: 'block',
                                                                  fontSize: 15,
                                                                  marginBottom: 5,
                                                              }}
                                                              onClick={() => viewSingleResult(cs)}
                                                          >
                                                              {cs.title}
                                                          </a>
                                                          <b style={{ fontSize: 11 }}>
                                                              {cs.city} - {cs.province}
                                                          </b>
                                                      </Popup>
                                                  </Marker>
                                              ),
                                      )
                                    : groupCoordinatesByRadius(filteredCulturalSites, mapZoom).map(
                                          (group) => (
                                              <Marker
                                                  key={`g${group.items[0].id}`}
                                                  position={group.center}
                                                  icon={divIcon({
                                                      html: `${group.items.length}+`,
                                                      className: 'map-circle-divicon',
                                                  })}
                                              />
                                          ),
                                      )}
                            </MapContainer>
                        )}
                        <List<CulturalSite>
                            bordered
                            className="search-list"
                            itemLayout="vertical"
                            split
                            size="small"
                            pagination={{
                                pageSize: 250,
                                showSizeChanger: false,
                                position: 'both',
                                // eslint-disable-next-line react/display-name
                                showTotal: (total, range) => (
                                    <div>
                                        {range[0]}-{range[1]} / {total}
                                    </div>
                                ),
                                style: { textAlign: 'center', marginBottom: 20 },
                            }}
                            dataSource={filteredCulturalSites}
                            renderItem={(item) => renderListItem(item, viewSingleResult)}
                        />
                    </Spin>
                    <Modal
                        title={outlinedItem?.title}
                        onCancel={() => {
                            setOutlinedItem(undefined);
                            setViewing(false);
                        }}
                        visible={isViewing}
                        className="search-detail"
                        footer={null}
                        destroyOnClose
                    >
                        <Spin spinning={isLoadingSingle}>
                            {outlinedItem?.lat && outlinedItem?.lng && (
                                <MapContainer
                                    center={outlinedItem}
                                    zoom={12}
                                    style={{
                                        height: 300,
                                        boxShadow: '0 0 10px grey',
                                        borderRadius: 7,
                                        marginBottom: 20,
                                        border: '1px solid grey',
                                    }}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={outlinedItem}>
                                        <Popup>{outlinedItem.title}</Popup>
                                    </Marker>
                                </MapContainer>
                            )}
                            <Descriptions
                                // title={outlinedItem?.title}
                                bordered
                                layout="vertical"
                                size="small"
                                column={1}
                            >
                                {outlinedItem?.image_url?.match(/.png|.jpg|.gif/) && (
                                    <Descriptions.Item label="Copertina">
                                        <Image
                                            alt={outlinedItem?.title}
                                            className="search-thumbnail"
                                            width={300}
                                            src={outlinedItem?.image_url}
                                        />
                                    </Descriptions.Item>
                                )}
                                {outlinedItem?.type && (
                                    <Descriptions.Item label="Tipo">
                                        {outlinedItem?.type}
                                    </Descriptions.Item>
                                )}
                                {outlinedItem?.fulladdress && (
                                    <Descriptions.Item label="Indirizzo">
                                        {outlinedItem?.fulladdress} {outlinedItem?.zipcode}
                                    </Descriptions.Item>
                                )}
                                {outlinedItem?.region && (
                                    <Descriptions.Item label="Regione">
                                        {outlinedItem?.region}
                                    </Descriptions.Item>
                                )}
                                {outlinedItem?.services && (
                                    <Descriptions.Item label="Servizi">
                                        {outlinedItem?.services === null
                                            ? '-'
                                            : outlinedItem?.services?.map((service) => (
                                                  <Tag
                                                      key={service}
                                                      color={SERVICES_COLORS[service]}
                                                  >
                                                      {service}
                                                  </Tag>
                                              ))}
                                    </Descriptions.Item>
                                )}
                            </Descriptions>
                            {outlinedItem?.description && (
                                <>
                                    <Divider>Descrizione</Divider>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: outlinedItem?.description || '',
                                        }}
                                    />
                                </>
                            )}
                            {!!outlinedItem?.openings?.length && (
                                <>
                                    <Divider>Apertura / Biglietti</Divider>
                                    <Descriptions
                                        // title={outlinedItem?.title}
                                        bordered
                                        layout="vertical"
                                        // layout="horizontal"
                                        size="small"
                                        column={1}
                                    >
                                        {outlinedItem?.openings.map((opening) => (
                                            <Descriptions.Item
                                                key={opening.label}
                                                label={opening.label}
                                            >
                                                {opening.description}
                                            </Descriptions.Item>
                                        ))}
                                    </Descriptions>
                                </>
                            )}
                            {(outlinedItem?.phones?.length ||
                                outlinedItem?.websites?.length ||
                                outlinedItem?.emails?.length) && (
                                <>
                                    <Divider>Contatti</Divider>
                                    <Descriptions
                                        // title={outlinedItem?.title}
                                        bordered
                                        layout="vertical"
                                        // layout="horizontal"
                                        size="small"
                                        column={1}
                                    >
                                        {!!outlinedItem?.phones?.length && (
                                            <Descriptions.Item label="Telefono">
                                                <ul>
                                                    {outlinedItem?.phones?.map((phone) => (
                                                        <li key={phone}>
                                                            <a
                                                                target="_blank"
                                                                href={`tel://${phone}`}
                                                                rel="noreferrer"
                                                            >
                                                                {phone}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Descriptions.Item>
                                        )}
                                        {!!outlinedItem?.emails?.length && (
                                            <Descriptions.Item label="Email">
                                                <ul>
                                                    {outlinedItem?.emails?.map((email) => (
                                                        <li key={email}>
                                                            <a
                                                                target="_blank"
                                                                href={email}
                                                                rel="noreferrer"
                                                            >
                                                                {email}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Descriptions.Item>
                                        )}

                                        {!!outlinedItem?.websites?.length && (
                                            <Descriptions.Item label="Sito Web">
                                                <ul>
                                                    {outlinedItem?.websites?.map(
                                                        (website) =>
                                                            website !== '' && (
                                                                <li key={website}>
                                                                    <a
                                                                        target="_blank"
                                                                        href={
                                                                            website.includes('http')
                                                                                ? website
                                                                                : `http://${website}`
                                                                        }
                                                                        rel="noreferrer"
                                                                    >
                                                                        {website}
                                                                    </a>
                                                                </li>
                                                            ),
                                                    )}
                                                </ul>
                                            </Descriptions.Item>
                                        )}
                                    </Descriptions>
                                </>
                            )}
                        </Spin>
                    </Modal>

                    <Footer className="footer">&copy; LiveMuseum.it</Footer>
                </div>
            </div>
        </Spin>
    );
};

export default App;
